.checkBoxTickBtn__round {
  position: relative;
}
.checkBoxTickBtn__round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 7px;
  cursor: pointer;
  height: 21px;
  left: 0;
  position: absolute;
  top: 0;
  width: 21px;
}
.checkBoxTickBtn__round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 3px;
  left: 5px;
  opacity: 0;
  position: absolute;
  top: 6px;
  transform: rotate(-45deg);
  width: 8px;
}
.checkBoxTickBtn__round input[type="checkbox"] {
  visibility: hidden;
}
.checkBoxTickBtn__round input[type="checkbox"]:checked + label {
  background-color: #275efe !important;
  border-color: #275efe !important;
}
.checkBoxTickBtn__round input[type="checkbox"]:checked + label:after {
  opacity: 1 !important;
}
