.Bl_outerdiv {
  /* height: 100vh; */
  width: 95%;
  /* background-color: rgb(14, 17, 46); */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: 0.2s all ease;
}
.Bl_navbardiv {
  width: 90%;
  height: 50px;
  transform: skew(-10deg);
  min-width: 280px;
  display: flex;
  justify-content: center;
  transition: 0.2s all ease;
}
.Bl_Navigator {
  width: 7%;
  background-color: #fff;
  transform: skew(-5deg);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 8px;
  height: 55px;
  transition: 0.2s all ease;
}
.Bl_Navigator:hover {
  background-color: #02a0db;
}
.Bl_arrow {
  font-size: 40px;
  font-weight: bolder;
}
.Bl_Optiondiv,
.Cl_Optiondiv {
  width: 40%;
  background-color: #fff;
  transform: skew(-5deg);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 8px 0px 0px;
  cursor: pointer;
  height: 55px;
  transition: 0.2s all ease;
}

.Bl_Red_Optiondiv,
.Cl_Red_Optiondiv {
  width: 27.5%;
  background-color: #fff;
  transform: skew(-5deg);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 10px 0px 0px;
  height: 70%;
  cursor: pointer;
  height: 42px;
  transition: 0.2s all ease;
}
.Bl_Nav_Text,
.Cl_Nav_Text {
  font-size: clamp(2rem, 2vw, 3.5rem);
  font-weight: 500;
  font-family: "Anton";
  color: #222637;
  transition: 0.2s all ease;
}
.Bl_Nav_Red_Text {
  font-size: clamp(1.5rem, 1.1vw, 2.3rem);
  font-weight: 400;
  font-family: "Anton";
  color: #222637;
  transition: 0.2s all ease;
}
.Cl_Nav_Red_Text {
  font-size: clamp(1.25rem, 1.1vw, 2.3rem);
  font-weight: 400;
  font-family: "Anton";
  color: #222637;
  transition: 0.2s all ease;
}

/**Claim Section*/
.Cl_Optiondiv {
  margin: 0px 2px;
}

.Cl_Red_Optiondiv {
  width: 20%;
  background-color: #fff;
  /* transform: skew(-10deg); */
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 2px;
  height: 70%;
  cursor: pointer;
}

@media screen and (min-width: 1400px) {
  .Bl_outerdiv {
    margin-bottom: 2rem;
  }
  .Bl_Navigator {
    height: 3.5vw;
  }
  .Bl_Optiondiv,
  .Cl_Optiondiv {
    height: 3.5vw;
  }
  .Bl_Red_Optiondiv,
  .Cl_Red_Optiondiv {
    height: 2vw;
  }
  .Cl_Optiondiv,
  .Cl_Red_Optiondiv {
    margin: 0px 8px 0px 0px;
  }
}
@media screen and (max-width: 1024px) {
  .Cl_Nav_Red_Text {
    font-size: clamp(1.125rem, 1.1vw, 2.3rem);
  }
  .Bl_Navigator {
    height: 60px;
    width: 8%;
  }
  .Bl_Navigator > img {
    width: 60% !important;
  }
  .Bl_Navigator__logo {
    width: 60%;
  }
  .Bl_Optiondiv,
  .Cl_Optiondiv {
    width: 60%;
    height: 60px;
  }
  .stake .Bl_Optiondiv,
  .Cl_Optiondiv {
    width: 45%;
  }
  .Bl_Red_Optiondiv,
  .Cl_Red_Optiondiv {
    width: 20%;
  }
  /* .Bl_Nav_Text,
  .Cl_Nav_Text {
    font-size: clamp(1rem, 3vw, 3rem);
  } */
}
@media screen and (max-width: 960px) {
  .Bl_Nav_Red_Text {
    font-size: clamp(1.25rem, 1.1vw, 2.3rem);
  }
}
@media screen and (max-width: 855px) {
  .claim-content .Cl_Nav_Red_Text {
    font-size: clamp(1rem, 1.1vw, 1.3rem);
  }
}
@media screen and (max-width: 807px) {
  .Bl_Nav_Red_Text {
    font-size: clamp(1.125rem, 1.1vw, 2.3rem);
  }
}
@media screen and (max-width: 770px) {
  .claim-content .Cl_Nav_Red_Text {
    font-size: clamp(0.8rem, 1.1vw, 2.3rem);
  }
}
@media screen and (max-width: 768px) {
  .selectproduct_box{
    width: 80% !important;
  }
  .Bl_Navigator {
    height: 60px;
    width: 8%;
  }
  .claim-content .Bl_Navigator > img {
    width: 80% !important;
  }
  .Bl_Navigator > img {
    width: 60% !important;
  }
  .Bl_Optiondiv,
  .Cl_Optiondiv {
    width: 60%;
    height: 60px;
  }
  .Bl_Red_Optiondiv,
  .Cl_Red_Optiondiv {
    width: 20%;
    height: 38px;
  }
  .Bl_Nav_Text,
  .Cl_Nav_Text {
    font-size: clamp(2rem, 3vw, 3rem);
  }
  .Bl_Nav_Red_Text {
    font-size: clamp(1rem, 1.1vw, 1.3rem);
  }
  .Cl_Nav_Red_Text {
    font-size: clamp(0.75rem, 1.1vw, 1.3rem);
  }
}
@media screen and (max-width: 700px) {
  .claim-content .Cl_Optiondiv {
    width: 40%;
  }
  .claim-content .Cl_Nav_Text {
    font-size: clamp(1.5rem, 3vw, 3rem);
  }
}
@media screen and (max-width: 688px) {
  .Bl_Nav_Red_Text {
    font-size: clamp(1rem, 1.1vw, 1.3rem);
  }
}
@media screen and (max-width: 660px) {
  .Bl_Nav_Red_Text {
    font-size: clamp(0.9rem, 1.1vw, 1.3rem);
  }
}
/* @media screen and (max-width: 650px) {
  .stake .Bl_Optiondiv,
  .Cl_Optiondiv {
    width: 40%;
  }
} */
@media screen and (max-width: 640px) {
  .stake .Bl_Nav_Red_Text {
    font-size: clamp(0.9rem, 1.1vw, 1.3rem);
  }
}
@media screen and (max-width: 630px) {
  .Bl_Nav_Red_Text {
    font-size: clamp(0.9rem, 1.1vw, 1.3rem);
  }
}
@media screen and (max-width: 600px) {
  .Bl_Nav_Text,
  .Cl_Nav_Text {
    font-size: clamp(1.5rem, 3vw, 3rem);
  }
  .Bl_Nav_Red_Text,
  .Cl_Nav_Red_Text {
    display: none;
  }
  .Bl_Navigator {
    margin: 0 5px;
  }
  .Bl_Red_Optiondiv,
  .Cl_Red_Optiondiv {
    width: 10%;
    height: 30px;
    margin: 0px 5px 0px 0px;
  }
  .stake .Bl_Optiondiv,
  .Bl_Optiondiv,
  .Cl_Optiondiv,
  .claim-content .Cl_Optiondiv {
    width: 60%;
    height: 35px;
    margin: 0px 5px 0px 0px;
  }
  .Bl_Navigator {
    height: 35px;
  }
}
@media screen and (max-width: 470px) {
  .claim-content .Cl_Nav_Text {
    font-size: clamp(1.25rem, 3vw, 3rem);
  }
}
@media screen and (max-width: 390px) {
  .claim-content .Cl_Nav_Text {
    font-size: clamp(1.125rem, 3vw, 3rem);
  }
}
@media screen and (max-width: 375px) {
  .claim-content .Cl_Nav_Text {
    font-size: clamp(1rem, 3vw, 3rem);
  }
}
