.confirm_box {
  width: 80%;
  /* height: 500px; */
  justify-content: space-between;
  flex-direction: row;
  align-items: flex-start;
  font-family: "Anton";
}
.claim_recipe__subHeading {
  font-size: 1.5rem;
  /* letter-spacing: -1px; */
}

.claim_sum {
  width: 60%;
  padding: 1rem 1.5rem;
}
/* claim recipe  */
.claim_recipe {
  width: 47%;
  /* min-height: 170px; */
  border-radius: 5px;
}
.claim_recipe__second {
  border-color: #c54f49;
}
.claim_recipe h4 {
  font-size: 1.125rem;
  color: #fff;
  margin: 15px 0px;
  font-weight: lighter;
  letter-spacing: -0.25px;
}
.claim_recipe > div {
  justify-content: space-between;
  display: flex;
  margin: 2px 0px;
}
.claim_recipe p {
  color: #b2b2b2;
  letter-spacing: -0.25px;
}
.claim_sum_2 {
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
}
/* detail box  */
.detail_box {
  width: 35%;
  border-left: 2px solid #9d9ea0;
  padding: 1rem 1.5rem;
  letter-spacing: -0.25px;
  height: 100%;
}
.detail_box p {
  color: #b2b2b2;
  word-break: break-all;
}
.detail_box span {
  color: white;
  margin-left: 0.5rem;
  word-wrap: break-word;
}
.detail_box .single_input,
.detail_box .multi-input {
  height: auto;
  margin-top: 15px;
}
.detail_box .multi-input > div {
  height: unset;
}
.selcet_nft_final {
  padding: 10px 30px;
}
@media (max-width: 1030px) {
  .claim_confirm_info br {
    display: none;
  }
}
@media (max-width: 339px) {
  .confirmBoxButton button {
    padding: 10px 60px !important;
  }
}
@media (max-width: 375px) {
  .detail_box span {
    margin-left: 0.125rem;
  }
}
@media screen and (max-width: 600px) {
  .selcet_nft_final {
    padding: 10px 40px;
  }
}
