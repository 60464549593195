.blend-content {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}
.blend-recipe {
  margin-top: 12vh;
  width: 75%;
  max-width: 1400px;
  padding: 10px 0px;
  scrollbar-width: none;
  transition: 0.25s all ease;
}
.blend-recipe::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
.blend-factory {
  margin-top: 50px;
  display: flex;
  justify-content: flex-start;
  width: 90%;
  flex-direction: column;
  align-items: center;
  transition: 0.25s all ease;
  min-width: 320px;
}
.slick-slide > div {
  display: flex;
  justify-content: center;
}

.blend-factory__progressBar {
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

@media screen and (max-width: 1400px) {
  .blend-recipe {
    /* margin-top: 80px; */
  }
}
@media screen and (max-width: 1360px) {
  .blend-recipe {
    width: 80%;
  }
}
@media screen and (max-width: 1200px) {
  .blend-recipe {
    width: 85%;
  }
}
@media screen and (max-width: 900px) {
  .blend-recipe {
    /* width: 90%; */
  }
}

@media screen and (max-width: 700px) {
  .blend-recipe {
    width: 70%;
  }
}
@media screen and (max-width: 600px) {
  .blend-recipe {
    width: 60%;
    margin-top: 8vh;
  }
  .acknowledge_second_2__round, .checkBoxTickBtn__round label {
    width: 20px !important;
    height: 20px !important;
  }
  .progresstest{
    font-size: 3.5rem !important;
  }
}

@media screen and (max-width: 375px) {
  .slick-prev {
    z-index: 1;
  }
  .slick-next {
    z-index: 1;
  }
  .blend-recipe {
    width: 75%;
  }
}
