.footer {
  position: fixed;
  width: 100%;
  bottom: 0;
  background-color: #12141d;
}

.footer h4 {
  font-size: clamp(0.7rem, 1vw, 1.1rem);
  font-weight: 400;
}

.footer img {
  max-width: 4.75rem;
}
