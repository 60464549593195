.shipping_form_box {
  width: 50%;
  background-color: #222637;
  margin-top: 50px;
  border: 3px solid #b2b2b2;
  border-radius: 8px;
  padding: 15px 30px;
  display: flex;
  text-align: left;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.sipping_form_box__heading {
  width: 100%;
}
.shipping_form_box h3 {
  font-size: 2rem;
  color: #fff;
  margin-top: 25px;
  font-weight: 400;
  letter-spacing: 0.075rem;
  font-family: "Anton";
}
.multi-input,
.multi-input-2 {
  display: flex;
  justify-content: space-between;
  height: 50px;
  margin-top: 15px;
  width: 100%;
}
.single_input input,
.multi-input input,
.multi-input-2 input {
  border: none;
  font-size: clamp(0.9rem, 4vw, 1.25rem) !important;
}

.multi-input > div {
  width: 48%;
  height: 50px;
}
.multi-input-2 > div {
  width: 32%;
  height: 50px;
}
.single_input {
  width: 100%;
  height: 50px;
  margin-top: 15px;
}
.PhoneInput {
  height: 100%;
  border-radius: 10px;
}
.ship_btn_div {
  width: 150px;
  margin-top: 0.5rem;
}
.PhoneInput input {
  background-color: rgb(255, 255, 255);
  border-radius: 0 10px 10px 0;
  width: 100%;
  height: 100%;
  padding: 0px 10px;
  outline: none;
  font-size: clamp(0.9rem, 4vw, 1.25rem);
  border: none;
  font-family: "Varela-Regular";
}
.PhoneInputCountrySelectArrow {
  position: absolute;
  right: -5px;
  bottom: 47%;
  width: 0.5rem;
  height: 0.5rem;
}
.PhoneInputCountrySelect {
  font-family: "Varela-Regular";
}
.PhoneInputCountry {
  padding: 1rem;
}
.multi-input-2__countrySelectDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
}
.multi-input-2__countrySelect,
.css-13cymwt-control {
  height: 100%;
}
.multi-input-2__countrySelectDiv > div {
  width: 100%;
}
.css-13cymwt-control {
  border-radius: 10px !important;
}
.css-1hb7zxy-IndicatorsContainer {
  display: none !important;
}
.ship_btn_div__button {
  border-radius: 50%;
}
.css-1dimb5e-singleValue {
  color: black !important;
}
.multi-input ::placeholder,
.multi-input-2 ::placeholder,
.single_input ::placeholder,
.css-1jqq78o-placeholder,
.css-qbdosj-Input input {
  font-size: clamp(0.9rem, 4vw, 1.25rem);
  font-weight: 400;
  color: #b6b4b2 !important;
  font-family: "Varela-Regular";
}
.css-1fdsijx-ValueContainer,
#react-select-5-listbox,
.css-1nmdiq5-menu {
  font-size: clamp(0.9rem, 4vw, 1.25rem) !important;
  font-weight: 400 !important;
  font-family: "Varela-Regular" !important;
  overflow-y: hidden !important;
}
.PhoneInputInput {
  font-size: clamp(0.9rem, 4vw, 1.25rem) !important;
  font-weight: 400;
}
.PhoneInput ::placeholder {
  font-size: clamp(0.9rem, 4vw, 1.25rem);
  font-weight: 400;
  letter-spacing: 0 !important;
}
.single_input :-ms-input-placeholder,
.single_input ::-ms-input-placeholder {
  font-size: clamp(0.9rem, 4vw, 1.25rem);
  font-weight: 400;
  color: #b6b4b2;
}
#react-select-3-listbox {
  margin: 0;
}

.multi-input ::-ms-input-placeholder,
.multi-input :-ms-input-placeholder,
.multi-input-2 :-ms-input-placeholder {
  font-size: clamp(0.9rem, 4vw, 1.25rem);
  font-weight: 400;
  color: #b6b4b2;
}

@media (max-width: 768px) {
  .shipBtnWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media (max-width: 600px) {
  .multi-input,
  .multi-input-2 {
    display: block;
  }
}

@media (max-width: 425px) {
  .ship_btn_div {
    margin: 0 auto;
  }
}
@media (max-width: 325px) {
  .shipping_form_box h3 {
    letter-spacing: unset;
  }
}
