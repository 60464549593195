.nft_stake {
  min-height: 250px;
  background-color: #222637;
  padding: 25px 30px;
  /* border: 2px solid #939498; */
  border: 2px solid #b2b2b2;
  border-radius: 8px;
  /* margin-top: 20px; */
  /* margin-top: 40px; */
}
/* .nft_stake > div {
  padding: 10px 10px;
} */
.nft_stake h3 {
  color: #fff;
  font-family: "Anton";
  font-weight: 400;
  font-size: clamp(1.8rem, 1.1vw, 1.3rem);
}
.nft_stake .slick-prev:before,
.nft_stake .slick-next:before {
  display: block;
}

.nft_stake p {
  color: #a8aaad;
  padding: 0px 5px;
  font-family: "Anton";
  font-size: 14px;
}
.stake_input {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.nft_stake input {
  border-radius: 7px;
}
.stake_input input-div {
  height: unset;
}
.nft_stake_bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 40px 0px;
}
.nft_stake_bottom > div {
  width: 80%;
}

.nft_stake_bottom .upper-div-2 {
  width: 15% !important;
  margin-bottom: 0px;
}
.leader_board {
  border-radius: 8px;
  margin: 10px 0px;
  /* height: 600px; */
  color: #fff;
  background-color: #222637;
}
.leader_board_upper {
  height: 100px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #9b9c9e;
  border-bottom: 1px solid #9b9c9e;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.leader_board h2 {
  text-align: center;
  font-size: 35px;
  font-family: "Anton";
  font-weight: 400;
}
.unstake-btn {
  border-radius: 8px;
}
/* leaderboard  */
table {
  width: 100%;
  border: 1px solid #9b9c9e;
}
thead td {
  border: 1px solid #9b9c9e;
  text-align: center;
  color: #b2b2b2;
  font-family: "Anton";
  font-weight: 400;
  font-size: 20px;
}
tbody td {
  border-left: 1px solid #9b9c9e;
  border-right: 1px solid #9b9c9e;
  text-align: center;
  font-family: "Varela-Regular";
  font-weight: 400;
  font-size: 18px;
  /* font-size:  ; */
}
.nft_stake .checkBoxTickBtn__round {
  margin-right: 0.5rem;
}
td {
  padding: 20px 10px;
}
.unstake_div {
  height: unset !important;
  min-height: 300px !important;
}
.unstake_empty {
  display: flex;
  align-items: center;
  justify-content: center;
}
.unstake_div .slick-slide > div {
  justify-content: flex-start;
}
.unstake-btn {
  /* height: 240px !important; */
  background-size: calc(max(90%, 120px)) !important;
}
.bottom_stake p {
  color: #000;
  padding: 8px 0px;
}
@media (min-width: 1460px) {
  .nft_stake_bottom .nft_stake {
    width: 85% !important;
  }
}
@media screen and (min-width: 1401px) {
  .upper-div-2 {
    max-width: 280px;
    margin-left: 2rem;
  }
  .nft_stake_bottom > .upper-div-2 {
    max-width: 180px;
    margin-left: 2rem;
  }
  .stakeNft .upper-div-2 {
    height: 200px !important;
    background-size: calc(max(100%, 120px)) !important;
  }
  .unStakeNft .unstake-btn {
    height: 240px !important;
    background-size: calc(max(100%, 120px)) !important;
  }
}
@media (min-width: 1100px) and (max-width: 1400px) {
  .unStakeNft .nft_stake_bottom .upper-div-2 {
    height: 200px;
  }
}
@media (max-width: 1100px) {
  .nft_stake_bottom {
    flex-direction: column;
  }
  .nft_stake_bottom .upper-div-2 {
    width: 100% !important;
  }
  .nft_stake_bottom > div {
    width: 100%;
    margin-bottom: 20px;
  }
  .unstake_div {
    height: auto !important;
  }
}
@media screen and (max-width: 768px) {
  .nft_stake_bottom .upper-div-2 h1 {
    font-size: 6vw;
  }
}
@media screen and (max-width: 980px) and (min-width: 768px) {
  .stake .Bl_Nav_Red_Text {
    font-size: clamp(1.25rem, 1.1vw, 2.3rem);
  }
}
@media (max-width: 600px) {
  .nft_stake_bottom .upper-div-2 h1 {
    font-size: 7vw;
  }
  .stake_input {
    flex-direction: column;
  }
  .stake_input > div {
    width: 100% !important;
    margin: 10px 0px;
  }
  .leader_board h2 {
    font-size: 28px;
  }
  .nft_stake {
    height: auto;
    padding: 25px 20px;
  }
  .nft_stake p {
    padding: 0px 20px;
  }
  .nft_stake .checkBoxTickBtn__round {
    margin-right: 0rem;
  }
  .nft_stake .slick-prev {
    left: -18px;
  }
  .nft_stake .slick-next {
    right: -8px;
  }
  /* .claim_nft_chkbox input {
    width: 20px;
    height: 25px;
    position: absolute;
    right: 2px;
    top: 2px;
  } */
}
