.checkBoxBtn__round {
  position: relative;
}
.checkBoxBtn__round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 7px;
  cursor: pointer;
  height: 21px;
  left: 0;
  position: absolute;
  top: 0;
  width: 21px;
}
.checkBoxBtn__round label:after {
  display: none;
}
.checkBoxBtn__round input[type="checkbox"] {
  visibility: hidden;
}
.checkBoxBtn__round input[type="checkbox"]:checked + label {
  background-color: #049ad5;
  border: 3px solid white;
}
.checkBoxBtn__round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}
