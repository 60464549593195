.claim_card_div {
  border-radius: 5px;
  border: 4px solid #767880;
  display: flex;
  /* justify-content: center;
    flex-direction: column; */
  position: relative;
  /* align-items: center; */
  margin: 10px 13px;
  cursor: pointer;
  transition: transform 0.2s;
}

.claim_card_div {
  transform: scale(1.05);
}

.claim_nft_no {
  position: absolute;
  top: -4px;
  left: -4px;
  background-color: #12141d;
  height: 35px;
  width: 35px;
  color: white;
  text-align: center;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  border-start-start-radius: 5px;
  border-end-end-radius: 5px;
}

.claim_nft_chkbox {
  position: absolute;
  right: 10px;
  top: 5px;
  color: white;
  border-radius: 2px;
}
.bottom_stake {
  bottom: 0px;
  background-color: #fff;
  height: 30px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.round input[type="checkbox"]:checked + label {
  background-color: #049ad5;
  border: 3px solid white;
}
.bottom_stake p {
  font-size: 0.8em !important;
  padding: 8px 0px;
}
.blend-factory .input-div .image,
.selecting_nfts .input-div .image,
.nft_stake .input-div .image {
  display: block;
  max-width: 100%;
  height: auto;
  transition: filter 0.5s linear;
}
.blend-factory .input-div .imgLoading,
.selecting_nfts .input-div .imgLoading,
.nft_stake .input-div .imgLoading {
  filter: blur(10px);
  clip-path: inset(0);
  transition: filter 0.5s linear;
}
.blend-factory .input-div .imgLoaded,
.selecting_nfts .input-div .imgLoaded,
.nft_stake .input-div .imgLoaded {
  filter: blur(0px);
  transition: filter 0.5s linear;
}
