.claimBtn {
  white-space: nowrap;
}
.claimBtn:hover {
  background-color: #47a8af !important;
  color: #12141d !important;
  transition-property: background-color, color;
  transition-timing-function: ease-in-out;
  transition-duration: 0.2s;
}
