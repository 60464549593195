.claim-content {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.claim-content h1,
.claim-content h3 {
  font-weight: 400;
}
.claim-content .input-div {
  width: max-content !important;
  /* height: 135px; */
}
.claim-content .blend-recipe {
  width: 85%;
}
.selecting_nfts {
  display: block;
  /* justify-content: center; */
  width: 100%;
  padding: 15px 30px;
  /* overflow: hidden; */
  background-color: #222637;
  border-radius: 8px;
  border: 2px solid #fff;
}

.selecting_nfts h3 {
  color: #fff;
  font-size: clamp(1.8rem, 1.1vw, 1.3rem);
  font-weight: 400;
  font-family: "Anton";
  color: rgb(233, 233, 233);
  padding: 0.375rem 0.625rem;
  transition: 0.2s all ease;
}

.claim_info {
  width: 100%;
  margin-top: 2%;
  padding: 1% 1%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-radius: 5px;
}
.claim-content .slick-next {
  right: -15px;
}
.claim-content .slick-prev {
  left: -25px;
}

.claim-content .slick-next:before,
.claim-content .slick-prev:before {
  font-size: 2rem;
  display: block;
}
/* responsive  */

@media screen and (max-width: 1300px) and (min-width: 601px) {
  .claim-content .slick-track,
  .claim-content .slick-slide {
    /* width: max-content !important; */
    height: max-content !important;
  }
}

@media screen and (max-width: 1100px) {
  .claim_info > div {
    margin-bottom: 20px;
  }
  .selectproduct_box {
    flex-direction: column;
    height: auto !important;
  }
  .first_box {
    border-right: none !important;
    width: 100% !important;
    border-bottom: 1px solid #fff;
  }
  .second_box {
    margin: 15px 0px;
    border-right: none !important;
    width: 100% !important;
  }
  .acknowledge_second {
    align-items: center;
  }
  .acknowledge_box {
    height: auto !important;
  }
  .acknowledge_second img {
    width: 200px !important;
  }
  .confirm_box {
    flex-direction: column !important;
  }
  .confirm_box > div {
    width: 100% !important;
  }
  .detail_box {
    border-left: none !important;
  }
}

@media (max-width: 900px) {
  .claim_info br {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .claim_info {
    flex-direction: column;
    justify-content: center;
  }
  .claim_info > div {
    width: 100%;
  }
  .claim_info__buttonDiv {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .acknowledge_second,
  .acknowledge_second_2 {
    flex-direction: column;
  }
  .acknowledge_second_2 {
    justify-content: center;
    align-items: center;
  }
  .acknowledge_second_2 > div {
    margin-top: 15px;
  }
  .shipping_form_box {
    width: 80% !important;
    padding: 15px 15px;
  }
  .claim_sum_2 {
    flex-direction: column !important;
  }
  .claim_recipe {
    width: 100% !important;
    margin-top: 10px;
  }
}

@media screen and (max-width: 600px) {
  .shipping_form_box {
    width: 90% !important;
    padding: 15px 10px;
  }
  .multi-input,
  .multi-input-2 {
    flex-direction: column !important;
    height: auto !important;
  }
  .multi-input > div,
  .multi-input-2 > div {
    width: 100% !important;
    margin-bottom: 15px;
  }
  .claim_recipe h4 {
    font-size: 15px !important;
  }
  /* .selecting_nfts {
    padding: 15px 40px;
  } */
  .selecting_nfts h3 {
    font-size: clamp(1rem, 2vw, 1.5rem);
  }
  /* .claim-content .slick-prev {
    left: -37px;
  } */

  /* .claim-content .slick-next {
    right: -25px;
  } */
  .single_input1 {
    margin-top: 0px !important;
  }
  .blend-recipe__claimnfts{
    padding: 10px 15px;
  }
}

@media (max-width: 425px) {
  .selecting_nfts h3 {
    font-size: clamp(1rem, 1.1vw, 1.5rem);
  }
}
