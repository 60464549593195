.recipe-outerdiv {
  width: 199px;
  min-width: 185px;
  height: 260px;
  background-color: #222637;
  border: 2px solid #2fef64;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  align-items: center;
  margin: 10px 7px;
  cursor: pointer;
  transition: transform 0.2s ease;
  position: relative;
}
.recipe-outerdiv:hover {
  transform: scale(1.05);
}
.recipe-bottom:hover {
  filter: none !important;
}
.info-top:hover {
  filter: none !important;
}
.recipe-bottom,
.info-div {
  position: absolute;
  bottom: 15px;
  left: 10px;
  width: 40%;
  height: 30px;
}
.info-div {
  width: 10%;
  right: 10px;
  left: auto;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
}
.recipe-top {
  top: 10px !important;
  right: 10px !important;
  position: absolute;
  /* width: 40%; */
  height: 30px;
}
.recipe-bottom button,
.recipe-top button {
  border: 1px solid #2fef64;
  color: #fff;
  background: transparent;
  border-radius: 5px;
  padding: 5px;
  font-size: clamp(15px, 2vw, 17px);
  font-weight: 400;
  font-family: "Anton";
}
.recipe-bottom button {
  padding-block: 2px;
  padding-inline: 5px;
  letter-spacing: 1px;
}
.recipe-top button {
  border: 1px solid #644681;
  background-color: #12141d;
}
img.recipe-img {
  -webkit-animation: mover 4s infinite alternate;
  animation: mover 4s infinite alternate;
}
img.recipe-img {
  -webkit-animation: mover 4s infinite alternate;
  animation: mover 4s infinite alternate;
}
info-div__tooltip {
  position: relative;
}
.info-div__tooltip-Box {
  display: block;
  position: absolute;
  right: -15px;
  text-align: center;
  min-width: 100px;
  min-height: 50px;
  background-color: #222637;
  padding: 0.25rem;
}
.info-div__tooltip-Box-Text {
  font-size: 1rem;
  color: #888888;
  margin-bottom: 0.25rem;
}

.info-div__tooltip-Box ul > li {
  list-style: none;
  font-size: 0.75rem;
}
.info-div__tooltip-Box li {
  color: #fff;
  font-size: 0.5rem;
}
.info-div__tooltip-Box-Icon {
  position: absolute;
  top: -12.5px;
  right: -10px;
  z-index: 999;
}
.info-div__tooltip-Box-bdElem {
  position: absolute;
  top: 0px;
  right: -5px;
  border: 2px solid #222637;
  width: 10px;
  border-radius: 5px;
}

@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}
@keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}

@media screen and (max-width: 1100px) and (min-width: 900px) {
  .recipe-outerdiv {
    width: calc(80%);
    height: 280px;
  }
  .recipe-top {
    right: 12px !important;
  }
  .recipe-bottom,
  .info-div {
    bottom: 15px;
  }
}
@media screen and (max-width: 768px) {
  .info-div__tooltip {
    display: none;
  }
}
@media screen and (max-width: 700px) and (min-width: 500px) {
  .recipe-outerdiv {
    /* width: 60%; */
    /* height: 310px; */
  }
  /* .recipe-top {
    width: 30%;
    right: 0px !important;
  } */
  .recipe-bottom,
  .info-div {
    bottom: 15px;
  }
}
@media screen and (max-width: 600px) {
  /* .recipe-top {
    width: 33%;
  } */
}

@media screen and (max-width: 600px) and (min-width: 425px) {
  .recipe-bottom,
  .info-div {
    bottom: 10px;
  }
}

@media screen and (max-width: 375px) {
  .recipe-top {
    /* width: 30%; */
    height: 280px;
  }
}
@media screen and (max-width: 320px) {
  .recipe-top {
    /* width: 35%; */
  }
}
