.selectproduct_box {
  border-radius: 8px;
  border: 3px solid #767880;
  display: flex;
  width: 70%;
  margin-top: 50px;
  min-height: 400px;
  max-width: 1024px;
  background-color: #222637;
}
.selectproduct_box .first_box .claim_card_div {
  max-width: 230px;
}
.first_box {
  border-right: 2px solid #767880;
}
.first_box h1,
.second_box h1 {
  color: #fff;
  margin-bottom: 10px;
}

.first_box {
  width: 40%;
}
.second_box {
  width: 60%;
}
.second_box,
.first_box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.25rem;
}
/* .second_first {
  background: url("../../updatedassets/bag.png");
}
.second_second {
  background: url("../../updatedassets/bag.png");
} */
.second_first:hover {
  background-color: #eef4f3;
  color: #12141d !important;
  transform: scale(1.05);
  transition-property: transform, background-color;
  transition: 0.2s ease-in-out;
}
.second_second:hover {
  background-color: #eef4f3;
  color: #12141d !important;
  transform: scale(1.05);
  transition-property: transform, background-color;
  transition: 0.2s ease-in-out;
}
.second_second,
.second_first {
  border: 3px solid #eef4f3;
  background-size: cover;
  background-repeat: no-repeat;
  width: 70%;
  height: 150px;
  border-radius: 8px;
  background-position: 110% 10%;
  background-size: clamp(50%, 80%, 200px);
  max-width: 350px;
  display: flex;
  color: #fff;
  align-items: center;
  cursor: pointer;
  transition-property: transform, background-color;
  transition: 0.2s ease-in-out;
  margin-bottom: 20px;
}

.second_first {
  border: 3px solid #eef4f3;
}
.second_first h3,
.second_second h3 {
  text-align: center;
  margin-left: 4%;

  font-size: clamp(1.5rem, 2vw, 1.75rem);
  text-align: left;
}

/* acknowledge box */
.acknowledge_box {
  min-height: 390px;
  height: auto;
  width: 80%;
  background-color: #222637;
  margin-top: 50px;
  border: 3px solid #b2b2b2;
  border-radius: 8px;
  padding: 1.5rem 1rem;
  margin-bottom: 1rem;
  min-width: 260px;
}

.acknowledge_second {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 30px;
}
.acknowledge_second > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-bottom: 25px;
}
.acknowledge_second_2 p {
  font-size: 20px;
  color: #fff;
  margin-left: 10px;
  transition: 0.2s font-size ease;
}
.acknowledge_second_2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 25px;
}
.acknowledge_second_2 input {
  width: 25px;
  height: 25px;
}
.acknowledge_second p {
  font-size: 20px;
  color: #b2b2b2;
  font-weight: normal;

  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: 0.25s width ease;
}
.acknowledge_second span {
  color: #fff;
}
.acknowledge_second button {
  width: 100px;
}
.acknowledge_second_2__round,
.checkBoxTickBtn__round {
  justify-content: flex-start !important;
}
.acknowledge_second_2__round,
.checkBoxTickBtn__round label {
  width: 25px;
  height: 25px;
}
.acknowledge_second_2__round,
.checkBoxTickBtn__round label::after {
  left: 7px;
  top: 8px;
}
.custom_heading{
  text-align: center;
  color: #fff;
  font-family: 'Anton';
  font-size: clamp(1.8rem, 2vw, 1.75rem);

}
@media (max-width: 768px) {
  .acknowledge_second_2 {
    padding: 0;
  }
  .acknowledge_second_2__round,
  .checkBoxTickBtn__round label {
    width: 20px;
    height: 20px;
  }
  .acknowledge_second_2__round,
  .checkBoxTickBtn__round label::after {
    left: 5px;
    top: 6px;
  }
  .checkBoxTickBtn__round label::after {
    width: 7px;
  }
}

@media (max-width: 440px) {
  .acknowledge_second_2 p {
    font-size: 18px;
  }
}
@media (max-width: 425px) {
  .second_second,
  .second_first {
    width: 90%;
  }
}
@media (max-width: 375px) {
  .acknowledge_second_2 > div:first-child {
    width: 100%;
  }
}

@media (max-width: 375px) {
  .acknowledge_second_2 > div:first-child {
    margin-left: 5%;
  }
}
@media (max-width: 355px) {
  .acknowledge_second p {
    width: 220px;
  }
}

@media (max-width: 340px) {
  .acknowledge_second_2 p {
    font-size: 16px;
  }
}
