.loading {
  width: 100%;
  /* height: 100vh; */
  /* background-color: #fff; */
  display: grid;
  place-items: center;
  margin: 15px 0px;
}
.loading div {
  width: 80px;
  height: 80px;
  background: radial-gradient(farthest-side, #222637 94%, #0000) top/9px 9px
      no-repeat,
    conic-gradient(#0000 30%, #9fa1ab);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 9px), #000 0);
  border-radius: 50%;
  animation: loading 800ms linear infinite;
}
@keyframes loading {
  to {
    transform: rotateZ(360deg);
  }
}

/* MEdium Loader  */

.medium {
  width: 80%;
  height: 100vh;
  background-color: #fff;
  display: grid;
  place-items: center;
}
.medium-div {
  width: 3.5rem;
  height: 3.5rem;
  border-bottom: 5px solid #fff;
  border-radius: 50%;
  animation: loading 800ms linear infinite;
  background: radial-gradient(farthest-side, #222637 94%, #0000) top/9px 9px;
  -webkit-mask: radial-gradient(farthest-side, #fff calc(100% - 9px), #fff 0);
}
@keyframes medium {
  to {
    transform: rotateZ(360deg);
  }
}

/* small loader  */
.small {
  /* width: 100%; */
  /* height: 100vh; */
  /* background-color: #fff; */
  display: flex;
  justify-content: center;
  margin-left: 10px;
}
.small-div {
  width: 2.5rem;
  height: 2.5rem;
  background: radial-gradient(farthest-side, #222637 94%, #0000) top/9px 9px
      no-repeat,
    conic-gradient(#0000 30%, #222637);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 9px), #000 0);
  /* border-bottom: 5px solid #dcdde0; */
  border-radius: 50%;
  animation: small-div 1s infinite linear;
}

/* small loader  */
.imageSmall {
  /* width: 100%; */
  /* height: 100vh; */
  /* background-color: #fff; */
  display: flex;
  justify-content: center;
  margin-left: 10px;
}
.image-small-div {
  width: 2.5rem;
  height: 2.5rem;
  background: radial-gradient(farthest-side, #ffffff 94%, #0000) top/9px 9px
      no-repeat,
    conic-gradient(#0000 30%, #ffffff);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 9px), #000 0);
  /* border-bottom: 5px solid #dcdde0; */
  border-radius: 50%;
  animation: small-div 1s infinite linear;
}
@keyframes small-div {
  to {
    transform: rotateZ(360deg);
  }
}

@media screen and (max-width: 768px) {
  .medium {
    width: 100%;
  }
}

/* small  */

@media (max-width: 600px) {
  .medium-div {
    width: 2.5rem;
    height: 2.5rem;
  }
}