* {
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
}
body {
  font-family: "Anton" !important;
}
.eCZoDi, .gHoDBx{
font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
}
@font-face {
  font-family: "Anton";
  src: local("Anton"), url("./fonts/Anton-Regular.woff");
}

@font-face {
  font-family: "Garet-normal";
  src: local("Garet-normal"), url("./fonts/Spacetype\ -\ Garet\ Book.otf");
}
@font-face {
  font-family: "Garet-bold";
  src: local("Garet-bold"), url("./fonts/Spacetype\ -\ Garet\ Heavy.otf");
}

@font-face {
  font-family: "Varela-Regular";
  src: local("Varela-Regular"), url("./fonts/VarelaRound-Regular.woff");
}
