.H_outerdiv {
  display: flex;
  justify-content: center;
}
.home_innerdiv {
  width: 90%;
  display: flex;
  justify-content: center;
  padding: 20px 0px;
  flex-direction: column;
  align-items: center;
}
.home_box {
  display: flex;
  justify-content: space-between;
  width: 80%;
}
.home_box > div {
  width: 48%;
  background-color: #1f2333;
  border-radius: 10px;
  padding: 20px 20px;
  cursor: pointer;
  position: relative;
  min-width: 260px;
}
.home_box h2 {
  color: #fff;
  font-size: clamp(2.75rem, 3.2vw, 3rem);
  margin: 30px 20px;
  line-height: 70px;
  font-family: "Anton";
  font-weight: 400;
  transition: font-size 0.2s ease;
}
.home_box span {
  font-size: clamp(2rem, 9vw, 7rem);
  font-weight: 600;
  letter-spacing: 5px;
  margin-bottom: clamp(2.1rem, 1vw, 1.25rem);
  transition: font-size 0.2s ease;
}
.home_innerdiv__connectBtnDiv {
  width: 80%;
  height: 45px;
  margin-bottom: clamp(2.5rem, 4vw, 3.75rem);
}
.gradient-overlay {
  position: absolute;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.722));
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  border-radius: 5px;
}
.blend_box,
.claim_box {
  border: 3px solid #c44f49;
  transition: all 0.2s ease;
  background-image: url("../assests/blender.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 320px 10%;
  background-size: 50%;
}
.blend_box:hover,
.claim_box:hover {
  background-position: 300px 10%;
  background-size: 55%;
}
.blend_box:hover {
  background-color: #be4d47;
  transform: scale(1.05);
  background-image: url("../assests/blender-color.png");
}
.blend_box_inner {
  transition: font-size 0.2s ease;
}
.blend_box:hover .blend_box_inner h2,
.claim_box:hover .blend_box_inner h2,
.stake_box:hover .blend_box_inner h2,
.comming-box:hover .blend_box_inner h2 {
  color: #000;
}
.blend_box_inner h2 {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.claim_box > div {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}
.claim_box {
  border: 3px solid #02a4e5;
  background-image: url("../assests/bag.png");
}
.claim_box:hover {
  background-color: #00a2e1;
  color: #000 !important;
  transform: scale(1.05);
  background-image: url("../assests/bag-color.png");
}

.claim_box img,
.blend_box img {
  width: 40px;
  position: absolute;
  bottom: 10px;
  right: 5px;
}

.stake_box {
  background-image: url("../assests/stake.png");
  border: 3px solid #f08900;
}
.stake_box:hover {
  background-image: url("../assests/stake-color.png");
  background-color: #f08900;
}
.comming-box {
  background-image: url("../assests/store.png");
  border: 3px solid #4347ad;
}
.comming-box:hover {
  background-image: url("../assests/store-color.png");
  background-color: #4347ad;
}
.homeWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
@media screen and (min-width: 2100px) {
  .blend_box,
  .claim_box {
    background-position: 520px 10% !important;
    background-size: 40% !important;
  }
}
@media screen and (min-width: 1900px) {
  .blend_box,
  .claim_box {
    background-position: 420px 10%;
    background-size: 40%;
  }
}
@media screen and (min-width: 1401px) {
  .blend_box,
  .claim_box {
    background-position: 360px 10%;
    background-size: 40%;
  }
}
@media (max-width: 1300px) {
  .home_box span {
    /* font-size: clamp(2rem, 9vw, 6rem); */
    font-size: 6rem;
  }
}
@media (max-width: 1200px) {
  .home_box {
    flex-direction: column;
    align-items: center;
    height: auto;
  }
  .home_box > div {
    width: 100%;
  }
  .home_box .claim_box {
    margin-top: 30px;
  }
  .blend_box,
  .claim_box {
    background-position: 420px 10%;
  }
  .blend_box:hover,
  .claim_box:hover {
    background-position: 420px 10%;
  }
}
@media (max-width: 768px) {
  .blend_box:hover,
  .claim_box:hover {
    background-position: 300px 10%;
  }
}
@media screen and (max-width: 765px) {
  .blend_box,
  .claim_box {
    background-position: 320px 10%;
  }
}
@media (max-width: 600px) {
  .blend_box,
  .claim_box {
    background-size: 60%;
    background-position: 200px 10%;
  }
  .home_box span {
    font-size: 5.5rem;
  }
  .home_box h2 {
    font-size: clamp(2rem, 3.2vw, 2rem);
  }
}
@media (max-width: 500px) {
  .home_box span {
    font-size: clamp(4.75rem, 1vw, 5rem);
    margin: 0;
  }
}

@media (max-width: 425px) {
  .blend_box,
  .claim_box {
    background-size: 75%;
    background-position: 150px 10%;
  }
  .blend_box:hover,
  .claim_box:hover {
    background-size: 75%;
    background-position: 140px 10%;
  }
  .home_box span {
    /* font-size: clamp(2rem, 9vw, 4rem); */
    font-size: clamp(4rem, 1vw, 4.75rem);
  }
  .home_box h2 {
    margin: 20px 8px;
  }
}
