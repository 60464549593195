body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', */
  /* 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #12141d;
  font-family: "Anton" !important;
}

#root {
  display: flex;
  justify-content: space-between;
  min-height: 100vh;
}

.flexCol {
  flex-direction: column;
}

.flexRow {
  flex-direction: row;
}

.flexBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flexEnd {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.ta-left {
  text-align: left;
}

/* @media screen and (min-width: 1100px) {
  #root {
    height: 100vh;
  }
} */
