.upper-div {
  width: 90%;
  display: flex;
  /* flex-direction: row; */
  justify-content: space-between;
  /* height: 200px; */
  align-items: center;
  transition: 0.2s all ease;
}
.ingredient-div {
  background-color: #222637;
  width: 80%;
  padding: 15px 30px;
  border: 2px solid #fff;
  border-radius: 8px;
  transition: 0.2s all ease;
  min-height: 200px;
}
.ingredient-div h3 {
  color: #fff;
  font-size: clamp(1.8rem, 1.1vw, 1.3rem);
  font-weight: 400;
  font-family: "Anton";
  /* text-align: center; */
  color: rgb(233, 233, 233);
  /* margin-left: 1.5rem; */
  padding: 0.375rem 0.625rem;
  transition: 0.2s all ease;
}
.ingredient-div__textContainer h3 {
  margin-left: 1.125rem;
}
.blendConfirm .ingredient-div h3 {
  text-align: left;
}
.confirmText {
  background-image: none !important;
}
.ingredient-div span {
  color: #ca75eb;
}
.select {
  filter: grayscale(1);
  border-radius: 10px;
  border: 6px solid #fff !important;
  position: relative;
}
.lock-icon {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 25px;
}
.input-div {
  max-width: 200px;
  display: flex !important;
  justify-content: center;
  border: 3px solid #9c9c9d;
  margin: 15px 8px;
  position: relative;
  border-radius: 5px;
}
.input-div img {
  border-radius: 0;
  z-index: -1;
}

.input-div-2 {
  border: 3px solid #767880;
  max-width: 180px;
}
.input-div-2 img {
  width: 100%;
  height: 100%;
}
.input-div-1 img {
  border-radius: 0;
  width: 100%;
  height: 100%;
}

.input-div h3 {
  position: absolute;
  top: -3px;
  left: -3px;
  background-color: #000;
  color: white;
  font-size: 12px;
  padding: 3px;
  letter-spacing: 0.7px;
  padding: 6px 10px;
  border-bottom-right-radius: 5px;
  border-top-left-radius: 9px;
}
.select h3 {
  left: -6px !important;
  top: -6px !important;
  border-top-left-radius: 5px;
}
.input-container {
  display: block;
  justify-content: flex-start;
  /* overflow-x: scroll; */
  transition: 0.2s all ease;
}

.input-container::-webkit-scrollbar {
  display: none;
}
.upper-div-2 {
  border: 4px solid #995eba;
  background-image: url("../../assests/rare.png");
  width: 15%;
  min-width: 150px;
  background-color: #222637;
  background-size: calc(max(75%, 120px));
  background-repeat: no-repeat;
  background-position: 50% 10%;
  border-radius: 8px;
  position: relative;
  display: flex;
  justify-content: center;
  cursor: pointer;
  height: 180px;
  max-height: 100%;
  cursor: pointer;
}
.upper-div-2.blendNow {
  transition: transform 0.2s ease-in-out;
}
.upper-div-2.blendNow:hover {
  transform: scale(1.05);
  transition: transform 0.2s ease-in-out;
}
.upper-div-2 img {
  width: 3.5vw;
  max-width: 80px;
  min-width: 50px;
  position: absolute;
  top: 0;
  right: 0;
  padding: 12px;
}
.upper-div-2 h1 {
  position: absolute;
  bottom: 0px;
  background-color: rgb(233, 233, 233);
  background-image: linear-gradient(rgba(0, 0, 0, 0.336), rgba(0, 0, 0, 0.722));
  background-size: 50%;
  font-size: 2.4vw;
  font-weight: 400;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  transition: 0.2s all ease;
}

.bottom-div {
  background-color: #222637;
  width: 100%;
  padding: 15px 20px;
  border: 2px solid #fff;
  border-radius: 8px;
  margin-top: 20px;
  padding: 15px 30px;
  margin-bottom: 20px;
  transition: 0.2s all ease;
}
.bottom-div h2 {
  color: #fff;
  font-size: clamp(1.8rem, 1.1vw, 1.3rem);
  /* letter-spacing: 2px; */
  /* text-align: left ; */
  font-weight: 400;
  padding: 6px 10px;
}
.bottom-div h3 {
  color: #fff;
  padding: 6px 10px;
  border-bottom-right-radius: 5px;
  border-top-left-radius: 5px;
}
.slick-next,
.slick-prev {
  z-index: 999;
}
.slick-next:before,
.slick-prev:before {
  font-size: 30px;
  display: none;
}
.slick-next {
  right: -15px;
}
.nft-cross {
  width: 20px !important;
  right: -10px;
  top: -50%;
  position: absolute;
  z-index: 1 !important;
}
/* responsive */

@media screen and (min-width: 2000px) {
  .upper-div-2 h1 {
    font-size: 2.5em;
  }
  .upper-div-2 {
    height: 240px;
    background-size: calc(max(60%, 150px));
  }
  .upper-div-2.blendConfirm {
    height: 240px !important;
    background-size: calc(max(65%, 120px)) !important;
  }
}

@media screen and (min-width: 1800px) and (max-width: 2000px) {
  .upper-div-2 h1 {
    font-size: 2.5em;
  }
  .upper-div-2 {
    height: 220px;
    background-size: calc(max(60%, 150px));
  }
}

@media (min-width: 1500px) and (max-width: 1800px) {
  .upper-div-2.blendNow {
    background-size: 120px;
  }
}

@media (min-width: 1460px) {
  .ingredient-div {
    width: 85%;
  }
}

@media screen and (min-width: 1401px) {
  .ingredient-div {
    max-height: 307px;
  }
  .ingredient-div img {
    max-height: 180px;
  }
  .upper-div-2 {
    max-width: 280px;
    margin-left: 2rem;
  }
  .upper-div-2 h1 {
    font-size: 2em;
  }
  .upper-div-2 img {
    width: 3.5vw;
  }
  .upper-div-2.blendConfirm {
    height: 220px;
    background-size: calc(max(60%, 150px));
  }
}
@media (max-width: 1401px) {
  .upper-div-2 {
    height: 180px;
  }
}

@media screen and (max-width: 1100px) {
  .upper-div {
    flex-direction: column-reverse;
    height: auto;
  }
  .ingredient-div,
  .upper-div {
    width: 100%;
  }
  .upper-div-2 {
    width: 100%;
    height: 85px;
    background-color: #222637 !important;
    align-items: center;
    margin-bottom: 20px;
    background-image: none !important;
  }
  .upper-div-2 img {
    width: 8.5%;
  }
  .upper-div-2 h1 {
    position: relative;
    font-size: 4.5vw;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.445));
  }
}
@media screen and (min-width: 768px) and (max-width: 1025px) {
  .upper-div-2,
  .ingredient-div,
  .bottom-div {
    width: 95%;
  }
  .upper-div-2 {
    height: 80px;
  }
  .upper-div-2 img {
    width: 8%;
  }
  .upper-div-2 h1 {
    font-size: 5vw;
  }
}
@media screen and (max-width: 1100px) {
  .slick-prev:before,
  .slick-next:before {
    display: block;
  }
}

@media screen and (max-width: 768px) and (min-width: 600px) {
  .upper-div-2 h1 {
    font-size: 9vw;
  }
  .upper-div-2 img {
    width: 8%;
  }
}
@media (max-width: 768px) {
  .ingredient-div h3 {
    font-size: clamp(1.3rem, 1.1vw, 1.3rem);
    margin-left: 0.5rem;
  }
}
@media screen and (max-width: 700px) {
  .blend-factory {
    /* .blend-recipe  */
    margin-top: 30px !important;
  }
}

@media screen and (max-width: 600px) {
  .ingredient-div h3,
  .bottom-div h2 {
    /* font-size: clamp(1.4rem, 1.1vw, 1.2rem); */
    font-size: clamp(1rem, 2vw, 1.5rem);
  }
  .upper-div-2,
  .ingredient-div,
  .bottom-div {
    width: 90%;
  }
  .ingredient-div {
    padding: 15px 30px;
  }
  .upper-div-2,
  .upper-div-2.blendNow {
    height: 55px;
  }
  .upper-div-2 img {
    width: 11%;
  }
  .upper-div-2 h1 {
    font-size: 7vw;
  }
  .input-div-1,
  .input-div-2 {
    width: auto !important;
    height: auto !important;
  }
  .input-div {
    max-width: unset;
    margin: 15px 20px;
  }

  .input-div-1 img {
    width: 100%;
  }
  .slick-next {
    right: -16px;
  }
  .input-div-2 img {
    width: 100%;
    height: 100%;
  }
  .blend-factory {
    /* .blend-recipe  */
    margin-top: 20px !important;
    /* width: 90% !important; */
    padding: 10px 15px;
  }
  .slick-prev:before,
  .slick-next:before {
    display: block;
  }
}

@media screen and (max-width: 425px) {
  .upper-div-2 {
    height: 50px;
  }
  .stakeNft .upper-div-2,
  .unStakeNft .unstake-btn {
    height: 55px;
  }
  .upper-div-2 img {
    width: 12%;
  }
  .upper-div-2 h1 {
    font-size: 7vw;
  }
  .blend-factory {
    /* .blend-recipe  */
    margin-top: 10px;
    /* width: 80%; */
  }
  .slick-prev:before,
  .slick-next:before {
    display: block;
  }
  .ingredient-div h3,
  .bottom-div h2 {
    font-size: clamp(1rem, 1.1vw, 1.5rem);
  }
}

@media screen and (max-width: 375px) {
  .upper-div-2 img {
    width: 15%;
  }
}
@media screen and (max-width: 320px) {
  .upper-div-2 h1 {
    font-size: 9vw;
  }
}

.staking-fix {
  border: 4px solid rgb(71, 168, 175);
  background-image: url("../../assests/stake-button.svg");
  width: 15%;
  min-width: 150px;
  background-color: rgb(34, 38, 55);
  background-size: calc(max(75%, 120px));
  background-repeat: no-repeat;
  background-position: 50% 10%;
  border-radius: 8px;
  position: relative;
  display: flex;
  justify-content: center;
  cursor: pointer;
  height: 180px;
  max-height: 100%;
  cursor: pointer;
}